// src/components/Home.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Spinner,
  SimpleGrid,
  Image,
  LinkBox,
  LinkOverlay,
  Flex,
} from '@chakra-ui/react';
import Header from '../Header';
import { Link } from 'react-router-dom';

const Home = () => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fixed color values
  const bgColor = '#ffffff'; // White background
  const textColor = '#333333'; // Dark text
  const boxBgColor = '#ffffff'; // White boxes

  useEffect(() => {
    fetch('https://hoobe-server-v5.onrender.com/application-all')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setApplications(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch applications. Please try again later.');
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <Box p={8} bg={bgColor} minH="100vh">
        <VStack spacing={8} align="center" maxW="1200px" w="100%" mx="auto">
          <Heading as="h1" size="2xl" mb={4} color={textColor} textAlign="center">
            Welcome to <Text as="span" color="#38B2AC">CreatorPass</Text>
          </Heading>

          {isLoading ? (
            <Spinner size="xl" color="#38B2AC" />
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={6} w="100%">
              {applications
                .filter(
                  (applicationData) =>
                    applicationData.application && applicationData.application.status
                )
                .map((applicationData) => {
                  const { id, imageURL } = applicationData;
                  const application = applicationData.application || {};

                  return (
                    <LinkBox
                      as="article"
                      key={id}
                      bg={boxBgColor}
                      borderRadius="30px"
                      boxShadow="md"
                      overflow="hidden"
                      _hover={{
                        boxShadow: 'lg',
                        transform: 'scale(1.02)',
                        transition: 'all 0.2s',
                        cursor: 'pointer',
                      }}
                      transition="all 0.2s"
                    >
                      {/* Main Application Image */}
                      <Image
                        src={application.image}
                        alt={application.title}
                        objectFit="cover"
                        w="100%"
                        h="200px"
                      />

                      {/* Details Section */}
                      <Box p={4}>
                        <Flex align="center">
                          {/* Host Image */}
                          {imageURL && (
                            <Image
                              src={imageURL}
                              alt={`${application.hostHandle} logo`}
                              boxSize="50px"
                              mr={4}
                              borderRadius="md"
                            />
                          )}
                          {/* Title and Host Handle */}
                          <VStack align="start" spacing={1}>
                            <Heading as="h3" size="md" color={textColor}>
                              <LinkOverlay as={Link} to={`/${id}`}>
                                {application.title}
                              </LinkOverlay>
                            </Heading>
                            <Text fontSize="sm" color="blue.500">
                              {application.hostHandle}
                            </Text>
                          </VStack>
                        </Flex>
                        {/* Description */}
                        <Text fontSize="sm" color={textColor} mt={2}>
                          {application.description}
                        </Text>
                      </Box>
                    </LinkBox>
                  );
                })}
            </SimpleGrid>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default Home;
