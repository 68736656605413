import React from 'react';
import { Box, Flex, Image, IconButton, Container, Link } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import logoImage from '../assets/hoobe.png';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
      navigate('/');
  };

  return (
    <Box bg="#222222" p={8}>
      <Container maxW="1200px" position="relative">
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Link as="div" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <Image src={logoImage} alt="Hoobe Logo" maxHeight="50px" />
          </Link>
        </Flex>
        {(location.pathname !== '/' || document.referrer) && (
          <IconButton
            icon={<ChevronLeftIcon boxSize={8} />}
            onClick={handleBack}
            position="absolute"
            left={0}
            top="50%"
            transform="translateY(-50%)"
            variant="ghost"
            color="white"
            _hover={{ bg: '#3f3f3f' }}
            aria-label="Go back"
            size="lg"
            fontSize="24px"
            p={2}
          />
        )}
      </Container>
    </Box>
  );
};

export default Header;